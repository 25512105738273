<template>
  <nav
    v-if="totalPages > 0"
    class="zpagination"
    :class="`text-${align}`"
  >
    <ul>
      <li v-if="!disableNextPrevButtons">
        <ZButton
          :disabled="currentPage === 1"
          variant="primary"
          pills
          outline
          @click="prev"
        >
          {{ $t('actions.prev') }}
        </ZButton>
      </li>
      <template v-if="showFirstPage">
        <li>
          <ZButton
            variant="primary"
            pills
            outline
            @click="change(1)"
          >
            1
          </ZButton>
        </li>
        <li class="dots">
          <ZButton
            variant="primary"
            pills
            outline
            disabled
          >
            ...
          </ZButton>
        </li>
      </template>
      <li
        v-for="index in pagination"
        :key="index"
        :class="{ current: index + 1 === currentPage }"
      >
        <ZButton
          :variant="index + 1 === currentPage ? 'highlight' : 'primary'"
          pills
          :outline="index + 1 !== currentPage"
          @click="change(index + 1)"
        >
          {{ index + 1 }}
        </ZButton>
      </li>
      <li v-if="!disableNextPrevButtons">
        <ZButton
          :disabled="currentPage === totalPages"
          variant="primary"
          pills
          outline
          @click="next"
        >
          {{ $t('actions.next') }}
        </ZButton>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'ZPagination',

  props: {
    currentPage: {
      type: Number,
      required: true,
    },

    total: {
      type: Number,
      required: true,
    },

    pageSize: {
      type: Number,
      required: true,
    },

    disableNextPrevButtons: {
      type: Boolean,
      default: false,
    },

    align: {
      type: String,
      default: 'center',

      validator: function (value) {
        return ['center', 'left', 'right'].includes(value)
      },
    },
  },

  emits: ['change'],

  data() {
    return {
      limit: this.$device.isMobile ? 3 : 5,
      steps: this.$device.isMobile ? 1 : 2,
    }
  },

  computed: {
    totalPages() {
      return Math.ceil(this.total / this.pageSize)
    },

    pagination() {
      const pages = Array.from(Array(this.totalPages).keys())

      if (this.currentPage <= this.steps) {
        return pages.slice(0, this.limit)
      }

      if (this.currentPage > this.steps && pages.length > this.limit) {
        return pages.slice(this.currentPage - (this.steps + 1), this.currentPage + this.steps)
      }

      return pages
    },

    showFirstPage() {
      return this.currentPage > this.limit
    },
  },

  methods: {
    change(index) {
      if (this.currentPage !== index) {
        this.$emit('change', index)
      }
    },

    prev() {
      if (this.currentPage > 1) {
        this.change(this.currentPage - 1)
      }
    },

    next() {
      this.change(this.currentPage + 1)
    },
  },
}
</script>
